import { axiosClient } from "../utils";
import { MetaResponse } from "../services";
import { Serializer } from "../services/api/serialize";
import {
  ValidStageProspecto,
  ValidStatusProspectos,
} from "../app/crm/prospects/utils";
import { ActivityLog, Lead, LeadComment, Team } from "../interfaces";
import { AxiosRequestConfig } from "axios";

const prefix = "/api/v1/brokers/crm";

const serializer = new Serializer();
export const crmService = {
  leads: {
    activityLog: async (id: number | string) => {
      const req = await axiosClient.get(`${prefix}/leads/${id}/activities`, {
        params: { include: "owner,owner.profile" },
      });

      const activityLog = serializer.deserialize(req.data)
        .data as ActivityLog[];
      return activityLog;
    },
    getAll: async (_params?: GetAllLeadsParams): Promise<ResponseGetLeads> => {
      const {
        page,
        include,
        filters,
        search,
        sort,
        onlySearchOpportunities,
        onlySearchProspects,
      } = _params || {};

      let queryParams: any = {};
      if (onlySearchProspects) {
        queryParams = {
          ...queryParams,
          "query[stage_in]": [
            ValidStageProspecto.PROSPECT,
            ValidStageProspecto.CONTACTED,
          ],
          "query[status_in]": [
            ValidStatusProspectos.EN_PROGRESO,
            ValidStatusProspectos.STANDBY,
            ValidStatusProspectos.WON,
          ],
        };
        if (filters?.stage) {
          queryParams["query[stage_in]"] = null;
        }
      }

      if (onlySearchOpportunities) {
        queryParams = {
          ...queryParams,
          "query[stage_in]": [
            ValidStageProspecto.APPOINTMENT,
            ValidStageProspecto.CLOSURE,
            ValidStageProspecto.NEGOTIATIONS,
            ValidStageProspecto.PAPERWORK,
            ValidStageProspecto.FOLLOWUP,
          ],
          "query[status_in]": [
            ValidStatusProspectos.EN_PROGRESO,
            ValidStatusProspectos.STANDBY,
          ],
        };

        if (filters?.stage) {
          queryParams["query[stage_in]"] = null;
        }
      }

      for (const [key, value] of Object.entries(filters || {})) {
        if (value !== null && value) {
          let _key = key;
          let _value = value;
          if (key === "type") {
            _key = "type_tag_key_eq";
          }
          if (key === "origin") {
            _key = "origin_tag_key_eq";
          }
          if (key === "medium") {
            _key = "medium_tag_key_eq";
          }
          if (key === "stage") {
            _key = "stage_in";
          }
          if (key === "agent_id") {
            _key = "agent_id_eq";
          }
          if (key === "team") {
            if (value === "*_blank") {
              _key = "team_id_blank";
              _value = 1;
            } else {
              _key = "team_id_eq";
            }
          }
          queryParams = {
            ...queryParams,
            [`query[${_key}]`]: _value,
          };
        }
      }

      const params: AxiosRequestConfig["params"] = {
        ...(include && { include: include.join(",") }),
        ...(page && { "page[number]": page.number, "page[size]": page.size }),
        ...(search && { "query[full_name_i_cont]": search }),
        ...(sort && { sort: Array.isArray(sort) ? sort.join(",") : sort }),
        ...queryParams,
      };
      const req = await axiosClient.get<Lead[]>(`${prefix}/leads`, { params });
      const serialized = serializer.deserialize(req.data);
      return serialized;
    },
    getOne: async (id: number | string): Promise<Lead> => {
      const req = await axiosClient.get<Lead[]>(`${prefix}/leads/${id}`, {
        params: {
          include: ["agent", "agent.profile", "type", "origin", "medium"].join(
            ","
          ),
        },
      });
      const serialized = serializer.deserialize(req.data);
      return serialized.data;
    },
    create: async (data: CreateLeadDto): Promise<Lead> => {
      const req = await axiosClient.post(`${prefix}/leads`, data);
      return serializer.deserialize(req.data).data as Lead;
    },
    update: async (id: string | number, data: UpdateLeadDto): Promise<Lead> => {
      const req = await axiosClient.patch(`${prefix}/leads/${id}`, data);
      return new Serializer().deserialize(req.data) as Lead;
    },
    delete: async (id: string | number) => {
      const req = await axiosClient.delete(`${prefix}/leads/${id}`);
      return req.data;
    },
  },
  comments: {
    getComments: async (id: number | string) => {
      const req = await axiosClient.get(`${prefix}/leads/${id}/comments`, {
        params: { include: "owner,owner.profile" },
      });
      return new Serializer().deserialize(req.data).data as LeadComment[];
    },
    create: async (idUser: number | string, data: UpdateCommentDto) => {
      const req = await axiosClient.post(
        `${prefix}/leads/${idUser}/comments`,
        data
      );
      return new Serializer().deserialize(req.data);
    },
    update: async (
      idUser: number | string,
      id: number | string,
      data: UpdateCommentDto
    ) => {
      const req = await axiosClient.patch(
        `${prefix}/leads/${idUser}/comments/${id}`,
        data
      );
      return new Serializer().deserialize(req.data);
    },
    delete: async (id: number | string) => {
      const req = await axiosClient.delete(`${prefix}/leads/${id}`);
      return req.data;
    },
  },
  teams: {
    getTeams: async () => {
      const req = await axiosClient.get(`${prefix}/teams?include=agents`);
      return new Serializer().deserialize(req.data) as ResponseGetTeams;
    },
    getTeam: async (id: number | string) => {
      const req = await axiosClient.get(`${prefix}/teams/${id}`);
      return new Serializer().deserialize(req.data).data as Team;
    },
    createTeam: async (data: CreateTeamDto) => {
      const req = await axiosClient.post(`${prefix}/teams`, data);
      return new Serializer().deserialize(req.data).data as Team;
    },
    updateTeam: async (id: number | string, data: UpdateTeamDto) => {
      const req = await axiosClient.patch(`${prefix}/teams/${id}`, data);
      return new Serializer().deserialize(req.data).data as Team;
    },
    deleteTeam: async (id: number | string) => {
      const req = await axiosClient.delete(`${prefix}/teams/${id}`);
      return req.data;
    },
  },
};

export interface GetAllLeadsParams {
  page?: {
    size: number | string;
    number: number | string;
  };
  filters?: any;
  search?: string | null;
  include?: (
    | "type"
    | "origin"
    | "agent"
    | "agent.profile"
    | "medium"
    | "agent.broker"
    | "activities"
    | "comments"
    | "team"
  )[];
  sort?: SortLead[] | SortLead | undefined | null;
  onlySearchProspects?: boolean;
  onlySearchOpportunities?: boolean;
}

export interface CreateTeamDto extends Omit<Team, "id" | "agents"> {
  agent_ids: (string | number)[];
}

export interface UpdateTeamDto extends Partial<CreateTeamDto> {}

type SortLead =
  | "-created_at"
  | "created_at"
  | "full_name"
  | "status"
  | "stage"
  | "type"
  | "origin"
  | "updated_at"
  | "-updated_at";

export interface ResponseGetLeads {
  data: Lead[];
  meta: MetaResponse;
}

export interface ResponseGetTeams {
  data: Team[];
  meta: MetaResponse;
}

export interface CreateLeadDto {
  email: string;
  full_name: string;
  cellphone: string;
  property_id: string | null;
  agent_id: string | null;
  type_tag_key: string | null;
  origin_tag_key: string | null;
  stage: string;
  status: string;
  team_id: string | null;
  /*   team: Team | null; */
  medium_tag_key: string | null;
}

export interface UpdateLeadDto extends Partial<CreateLeadDto> {}

export interface CreateCommentDto {
  title: string;
  comment: string;
}

export interface UpdateCommentDto extends Partial<CreateCommentDto> {}
