import { _useDarkMode, ThemeType } from "../store/_useDarkMode";
import React from "react";

export function useDarkMode(): { theme: ThemeType; toggle: () => void } {
  const { theme, setTheme } = _useDarkMode();

  const toggle = React.useCallback(() => {
    if (localStorage.getItem("color-theme")) {
      if (localStorage.getItem("color-theme") === "light") {
        document.documentElement.classList.add("dark");
        localStorage.setItem("color-theme", "dark");
        setTheme("dark");
      } else {
        document.documentElement.classList.remove("dark");
        localStorage.setItem("color-theme", "light");
        setTheme("light");
      }
    } else {
      if (document.documentElement.classList.contains("dark")) {
        document.documentElement.classList.remove("dark");
        localStorage.setItem("color-theme", "light");
        setTheme("light");
      } else {
        document.documentElement.classList.add("dark");
        localStorage.setItem("color-theme", "dark");
        setTheme("dark");
      }
    }
  }, [setTheme]);

  React.useEffect(() => {
    const storedTheme = localStorage.getItem("color-theme");

    if (storedTheme) {
      if (storedTheme === "dark") {
        document.documentElement.classList.add("dark");
        setTheme("dark");
      } else {
        document.documentElement.classList.remove("dark");
        setTheme("light");
      }
    } else {
      const prefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (prefersDark) {
        document.documentElement.classList.add("dark");
        setTheme("dark");
      } else {
        document.documentElement.classList.remove("dark");
        setTheme("light");
      }
    }

    // Añadir event listener para detectar cambios en la preferencia del sistema
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    const handleDarkModeChange = (event: MediaQueryListEvent) => {
      if (!localStorage.getItem("color-theme")) {
        if (event.matches) {
          document.documentElement.classList.add("dark");
          setTheme("dark");
        } else {
          document.documentElement.classList.remove("dark");
          setTheme("light");
        }
      }
    };

    darkModeMediaQuery.addEventListener("change", handleDarkModeChange);

    // Limpiar el event listener cuando el componente se desmonte
    return () => {
      darkModeMediaQuery.removeEventListener("change", handleDarkModeChange);
    };
  }, [setTheme]);

  return { theme, toggle };
}
