import { memo } from "react";
import { lazy } from "react";
import { numericFormatter } from "react-number-format";
import { teamsPages } from "../app/crm/teams/utils";
/* import { teamsPages } from "../app/crm/teams/utils"; */

export const ENV = {
  API_URL: import.meta.env.VITE_SPOINT_API_URL as string,
  GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string,
  MICROSERVICEGEN: "https://bl-pdf-gen.fly.dev",
  /*   MICROSERVICEGEN: "http://localhost:3000", */
};

export const breakpoints = {
  mobile: 0,
  sm: 640,
  md: 748,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export const formatter = {
  format: (price: number, currency?: string) =>
    numericFormatter(price.toString(), {
      decimalScale: 2,
      decimalSeparator: ".",
      thousandSeparator: ",",
      ...(currency && { prefix: currency + " $" }),
    }),
};

export interface PageProps {
  path: string;
  authoritys: "*";
  component: React.MemoExoticComponent<
    React.LazyExoticComponent<React.FunctionComponent<any>>
  >;
  fnPath?(query: string | number | any[]): string;
}

export const protectedRoutes = {
  dashboard: {
    path: "/broker/dashboard",
    component: memo(lazy(() => import("@/app/dashboard"))),
    authoritys: "*",
  } as PageProps,
  properties: {
    path: "/broker/properties",
    component: memo(lazy(() => import("@/app/properties"))),
  } as PageProps,
  propertiesCreate: {
    path: "/broker/properties/create",
    component: memo(lazy(() => import("@/app/properties-create"))),
  } as PageProps,
  propertiesEdit: {
    path: "/broker/properties/edit/:id",
    fnPath: (id) => `/broker/properties/edit/${id}`,
    component: memo(lazy(() => import("@/app/properties-edit"))),
  } as PageProps,
  propertiesView: {
    path: "/broker/properties/view/:id",
    fnPath: (id) => `/broker/properties/view/${id}`,
    component: memo(lazy(() => import("@/app/properties-view"))),
  } as PageProps,
  profile: {
    path: "/broker/profile",
    component: memo(lazy(() => import("@/app/profile"))),
  } as PageProps,
  agents: {
    path: "/broker/agents",
    component: memo(lazy(() => import("@/app/agents"))),
    authoritys: "*",
  } as PageProps,
  agentView: {
    path: "/broker/agents/:id",
    fnPath: (id) => `/broker/agents/${id}`,
    component: memo(lazy(() => import("@/app/agent"))),
    authoritys: "*",
  } as PageProps,
  tags: {
    path: "/broker/tags",
    component: memo(lazy(() => import("@/app/tags"))),
    authoritys: "*",
  } as PageProps,
  prospects: {
    path: "/broker/crm/prospects",
    component: memo(lazy(() => import("@/app/crm/prospects"))),
    authoritys: "*",
  } as PageProps,
  prospect: {
    path: "/broker/crm/prospects/:id",
    component: memo(lazy(() => import("@/app/crm/prospects/[id]"))),
    fnPath: (id) => `/broker/crm/prospects/${id}`,
    authoritys: "*",
  } as PageProps,
  statistics: {
    path: "/broker/crm/statistics",
    component: memo(lazy(() => import("@/app/crm/statistics"))),
    authoritys: "*",
  } as PageProps,
  opportunities: {
    path: "/broker/crm/opportunities",
    component: memo(lazy(() => import("@/app/crm/opportunities"))),
    authoritys: "*",
  } as PageProps,

  crm: {
    path: "/broker/crm",
    component: memo(lazy(() => import("@/app/crm/index"))),
    authoritys: "*",
  } as PageProps,
  builder: {
    path: "/broker/website",
    component: memo(lazy(() => import("@/app/builder"))),
    authoritys: "*",
  } as PageProps,

  ...teamsPages,
};

export const publicRoutes = {
  home: {
    path: "/",
    component: memo(lazy(() => import("@/app/login"))),
    authoritys: "*",
  } as PageProps,
  sigunp: {
    path: "/create-account",
    component: memo(lazy(() => import("@/app/create-account"))),
    authoritys: "*",
  } as PageProps,
  sentryTest: {
    path: "/sentry-test",
    component: memo(lazy(() => import("@/app/sentry-test"))),
    authoritys: "*",
  } as PageProps,
  /*     recoverPassword: {
      path: "/recover/password/:token/:iduser",
      component: React.memo(React.lazy(() => import("@/app/recover/password"))),
      authoritys: "*",
    } as PageProps,
    verifyEmailSent: {
      path: "/verify/email",
      component: React.memo(React.lazy(() => import("@/app/verify/emailSent"))),
      authoritys: "*",
    } as PageProps, */
};

export const validPaths = {
  ...protectedRoutes,
  ...publicRoutes,
};

export const colors = {
  dark: {
    900: "#0C1317",
    800: "#111B21",
    700: "#182229",
    600: "#202C33",
    500: "#1F2937",
  },
};

export const countryList = [
  {
    name: "México",
    currency: "MXN",
    phone: 52,
    capital: "Mexico City",
    code: "MX",
    code3: "MEX",
    continent: "América",
  },
  {
    name: "Estados Unidos",
    currency: "USD",
    phone: 1,
    capital: "Washington",
    code: "US",
    code3: "USA",
    continent: "América",
  },
  {
    name: "Guatemala",
    currency: "GTQ",
    phone: 502,
    capital: "Guatemala City",
    code: "GT",
    code3: "GTM",
    continent: "América",
    number: 320,
  },
];

export const ubicacionesTemp = [
  // GUATEMALA UBICACIONES
  { code: "GT", key: "zona1guatemala", label: "Zona 1" },
  { code: "GT", key: "zona2guatemala", label: "Zona 2" },
  { code: "GT", key: "zona4guatemala", label: "Zona 4" },
  { code: "GT", key: "zona5guatemala", label: "Zona 5" },
  { code: "GT", key: "zona9guatemala", label: "Zona 9" },
  { code: "GT", key: "zona10guatemala", label: "Zona 10" },
  { code: "GT", key: "zona11guatemala", label: "Zona 11" },
  { code: "GT", key: "zona12guatemala", label: "Zona 12" },
  { code: "GT", key: "zona13guatemala", label: "Zona 13" },
  { code: "GT", key: "zona14guatemala", label: "Zona 14" },
  { code: "GT", key: "zona15guatemala", label: "Zona 15" },
  { code: "GT", key: "zona16guatemala", label: "Zona 16" },
  { code: "GT", key: "zona18guatemala", label: "Zona 18" },
  { code: "GT", key: "amatitlan", label: "Amatitlán" },
  { code: "GT", key: "carreterasalvador	", label: "Carretera a El Salvador " },
  { code: "GT", key: "fraijanes", label: "Fraijanes" },
  { code: "GT", key: "mixco	", label: "Mixco" },
  { code: "GT", key: "muxbal", label: "Muxbal" },
  { code: "GT", key: "sanjosepinula	", label: "San José Pinula" },
  { code: "GT", key: "altaverapaz", label: "Alta Verapaz" },
  { code: "GT", key: "chimaltenango", label: "Chimaltenango" },
  { code: "GT", key: "escuintla", label: "Escuintla" },
  { code: "GT", key: "puertosanjose", label: "Puerto San José" },
  { code: "GT", key: "puertoquetzal", label: "Puerto Quetzal" },
  { code: "GT", key: "monterricoiztapa", label: "Monterrico / Iztapa" },
  { code: "GT", key: "palin", label: "Palín" },
  { code: "GT", key: "santacatarinapinula", label: "Santa Catarina Pinula" },
  { code: "GT", key: "izabal", label: "Izabal" },
  { code: "GT", key: "atitlan", label: "Atitlan" },
  { code: "GT", key: "laguna", label: "Laguna del Pino" },
  { code: "GT", key: "xela", label: "Xela" },

  // MÉXICO
  { code: "MX", key: "mty", label: "Monterrey" },
  { code: "MX", key: "garcia", label: "García" },
  { code: "MX", key: "stacat", label: "Santa Catarina" },
  { code: "MX", key: "playcar", label: "Playa del Carmen" },
  { code: "MX", key: "tulum", label: "Tulum" },
  { code: "MX", key: "cancun", label: "Cancún" },
  { code: "MX", key: "spgg", label: "San Pedro Garza García" },
  { code: "MX", key: "santiago", label: "Santiago" },
  { code: "MX", key: "ptomorelos", label: "Puerto Morelos" },
  { code: "MX", key: "apodaca", label: "Apodaca" },
  { code: "MX", key: "lcabos", label: "Los Cabos" },
  { code: "MX", key: "sannico", label: "San Nicolás" },
  { code: "MX", key: "gua", label: "Guadalupe" },
  { code: "MX", key: "cade", label: "Cadereyta" },
  { code: "MX", key: "esco", label: "Escobedo" },
  { code: "MX", key: "jua", label: "Juárez" },
  { code: "MX", key: "salin", label: "Salinas Victoria" },
  { code: "MX", key: "elcar", label: "El Carmen" },
  { code: "MX", key: "allen", label: "Allende" },
  { code: "MX", key: "slp", label: "San Luis Potosi" },
  { code: "MX", key: "yucatan", label: "Yucatán" },
  { code: "MX", key: "cienega", label: "Ciénega de Flores" },
  // USA
  { code: "US", key: "hou", label: "Houston" },
  { code: "US", key: "woodlandstx", label: "The Woodlands" },
  { code: "US", key: "conroetx", label: "Conroe" },
  { code: "US", key: "san", label: "San Antonio" },
];
