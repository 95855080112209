import { ENV } from "../utils";

export * from "../services/auth";
export * from "../services/broker";
export * from "../services/crm";

export interface ManageErrorResponse {
  errorKey?: string;
}

export interface ManageErrorApiResponse {
  error: string;
  "field-error": string[];
}

if (!ENV.API_URL) {
  throw new Error("Debes colocar la env.VITE_SPOINT_API_URL para funcionar");
}

export function transformarObjetoAFilter(objeto: {
  [key: string]: string | number | string[] | null | undefined;
}): any {
  const filters = {} as any;

  for (const key in objeto) {
    if (Object.prototype.hasOwnProperty.call(objeto, key)) {
      const objetoKey = objeto[key];
      filters[`filters[${key}]`] = (() => {
        if (Array.isArray(objetoKey)) {
          return objetoKey.join(",");
        }
        return objetoKey;
      })();
    }
  }

  return filters;
}

export interface MetaResponse {
  pagination: PaginationResponse;
}

export interface PaginationResponse {
  current: number;
  records: number;
}
