import * as React from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { PageProps, protectedRoutes, publicRoutes, validPaths } from "@/utils";
import AppRoute from "./AppRoute";
import Loader from "../@ui/Loader";
import PublicProtectedRoute from "./PublicProtectedRoute";
import { AnimatePresence } from "framer-motion";

const DashboardLayout = React.lazy(() => import("@/layouts/DashboardLayout"));

interface IAppRouterProps {}

const keysProtectRoutes = Object.keys(protectedRoutes);
const keysPublicRouters = Object.keys(publicRoutes);

const AppRouter: React.FunctionComponent<IAppRouterProps> = (props) => {
  const {} = props;

  const location = useLocation();
  return (
    <React.Suspense fallback={<Loader loading />}>
      <AnimatePresence>
        <Routes
        // TODO: disabled animation because in properties page give me an issue with pagination, shit
        /* location={location.pathname} key={location.pathname} */
        >
          <Route
            element={
              <PublicProtectedRoute>
                <Outlet />
              </PublicProtectedRoute>
            }
          >
            {keysPublicRouters.map(renderRouteApp)}
          </Route>
          <Route
            path="/broker"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <AnimatePresence>
                    <Outlet />
                  </AnimatePresence>
                </DashboardLayout>
              </ProtectedRoute>
            }
          >
            <Route
              path="/broker"
              index
              element={<Navigate to={validPaths.dashboard.path} />}
            />
            {keysProtectRoutes.map(renderRouteDashboard)}
          </Route>
          <Route
            path="*"
            element={<Navigate to={validPaths.home.path} replace />}
          />
        </Routes>
      </AnimatePresence>
    </React.Suspense>
  );
};

const renderRouteApp = (key: string, idx: number) => {
  const route: PageProps = publicRoutes[key as keyof typeof publicRoutes];
  return (
    <Route
      key={key.toUpperCase() + idx}
      path={route.path}
      element={<AppRoute component={route.component} />}
    />
  );
};

const renderRouteDashboard = (key: string, idx: number) => {
  const route: PageProps = protectedRoutes[key as keyof typeof protectedRoutes];

  return (
    <Route
      key={key + idx}
      path={route.path}
      element={
        <React.Suspense fallback={<Loader loading />}>
          <AppRoute component={route.component} />
        </React.Suspense>
      }
    />
  );
};

export default AppRouter;
